import qs from 'qs'
import routes from './router/routes'
import store from './store/index'
import i18n from './locale/index'
import request from './api/request'
import { message } from 'ant-design-vue'
const math = require('mathjs')
math.config({
  number: 'BigNumber', // Default type of number:
  precision: 20 // Number of significant digits for BigNumbers
})

// 获取国家logo
export function getCountryLogo (currency) {
  const countries = require('@/json/country.json')
  const country = countries.find(country => country.currency === currency)
  if (!country) return require('@/assets/country-images/money.gif')
  else return require('@/assets/country-images/' + country.country3Code + '.gif')
}

// 获取所在地名称
export function getCountryName (iso, list) {
  if (!iso) return '-'
  const lan = localStorage.getItem('locale-language') || 'zh'
  let countryName = ''
  for (const li in list) {
    if (list[li].areaCode === iso) {
      countryName = lan === 'zh' ? list[li].areaChineseName : list[li].areaEnglishName
      break
    }
  }
  return countryName
}

// 获取按钮或模块权限
export const getUsedCheck = (pageLabs, pageId) => {
  if (pageLabs && pageLabs.length !== 0) {
    return pageLabs.some(item => item.id === pageId)
  } else {
    return false
  }
}

// 复制
export function copyToClipboard (content) {
  if (window.clipboardData) {
    window.clipboardData.setData('text', content)
  } else {
    (function (content) {
      document.oncopy = function (e) {
        e.clipboardData.setData('text', content)
        e.preventDefault()
        document.oncopy = null
      }
    })(content)
    document.execCommand('Copy')
  }
  message.success(i18n.global.t('common_text_021'))
}

// 处理金额
export function parseMoney(nStr, currency) {
  if (!nStr) return 0
  const m = (nStr + '').split('.')
  if (currency === 'JPY') nStr = m[0]
  else if (m.length === 2 && m[1].length > 2) nStr = Number(nStr).toFixed(2)
  nStr += ''
  const x = nStr.split('.')
  let x1 = x[0]
  const x2 = x.length > 1 ? '.' + (x[1].length === 1 ? x[1] + '0' : x[1]) : '.00'
  const rgx = /(\d+)(\d{3})/
  while (rgx.test(x1)) x1 = x1.replace(rgx, '$1' + ',' + '$2')
  return (x1 + x2)
}

export function evalMathStr(str) {
  try {
    return math.eval(str).toString() * 1
  } catch (e) {
    throw e
  }
}

export function customRound(num) { // 实现当数字的小数部分为0.5时进行银行家舍入法，否则进行标准四舍五入
  // 判断小数部分是否为0.5
  if (num % 1 === 0.5) {
    // 使用银行家舍入法
    const integerPart = Math.floor(num)// Math.floor()向下舍入到最接近的整数
    return (integerPart % 2 === 0) ? integerPart : Math.ceil(num)// Math.ceil()向上舍入到整数
  } else {
    // 使用标准的四舍五入
    return Math.round(num)
  }
}

// 人民币付款 根据渠道类型，提供模板下载
export function matchType (typeId) {
  const tradeTypes = ['121011', '227020', '122030']
  return tradeTypes.includes(typeId)
}

// 获取验证码链接
export const getIdentyImg = (userName) => {
  return `${request.defaults.baseURL}/user/getIdentyImg?${Math.random()}&userName=${userName}`
}

// 路由处理
export function dealRouterMenus (menuList) {
  for (const menu of menuList) {
    const findRoute = routes.find(route => route.id === menu.id)
    if (findRoute) {
      menu.order = findRoute.order
      menu.icon = findRoute.icon
      if (menu.leaves) {
        menu.subKey = findRoute.path
        menu.title = findRoute.meta.title
        for (const leave of menu.leaves) {
          const findLeave = findRoute.children.find(child => child.id === leave.id)
          if (findLeave) {
            leave.order = findLeave.order
            leave.linkTo = findLeave.path
            leave.title = findLeave.meta.title
          }
        }
        menu.leaves = menu.leaves.sort((a, b) => a.order - b.order)
      } else {
        menu.linkTo = findRoute.children[0].path
        menu.title = findRoute.children[0].meta.title
      }
    }
  }
  return [...menuList].sort((a, b) => a.order - b.order)
}

// 处理字段映射列表
export function getAttrStatusList(key) {
  const systemMap = store.getters.statusAll
  const o = systemMap[key]
  let res = []
  for (const k in o) {
    const v = o[k]
    const s = {
      code: k,
      name: v
    }
    res.push(s)
  }
  if (key === 'receiveTransType') {
    res = res.sort((a, b) => {
      if (a.code === '00' && b.code !== '00') return -1
      if (a.code !== '00' && b.code === '00') return 1
      if (a.code === '10' && b.code !== '10') return 1
      if (a.code !== '10' && b.code === '10') return -1
      return 0
    })
  }
  return res
}

export function getArrayStatusName (status, type) {
  if (!status) return '-'
  let str = ''
  const statusArray = status.split(',')
  statusArray.forEach(item => {
    if (str) str = `${str},${getTradeStatusName(item, type)}`
    else str += getTradeStatusName(item, type)
  })
  return str
}

// 单字段映射列表
export function getTradeStatusName(status, type) {
  const systemMap = store.getters.statusAll
  let item = '-'
  const statusList = systemMap[type]
  for (const key in statusList) {
    if (key === status) {
      item = statusList[key]
      break
    }
  }
  return item
}

// 动态处理表头
export function formatColumns(titles, data) {
  let titleList = titles
  if (data) {
    const titleKeys = Object.keys(data[0])
    titleList = titles.filter(it => {
      return titleKeys.includes(it.key)
    })
  }
  const columns = []
  for (const item of titleList) {
    columns.push({
      key: item.key,
      dataIndex: item.key,
      title: item.title,
      width: 200,
      ellipsis: true
    })
  }
  return columns
}

// 获取uuid
export function generateUUID() {
  var d = new Date().getTime()
  if (window.performance && typeof window.performance.now === 'function') {
    d += performance.now()
  }
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })

  return uuid.replace('-', '')
}

// 获取文件后缀
export function fileIsImage(fileName) {
  const splitOpts = fileName.split('.')
  const fileSuffix = splitOpts[splitOpts.length - 1]
  return ['JPEG', 'JPG', 'PNG'].includes(fileSuffix.toUpperCase())
}

// 公用下载文件方法
export const commonFormatDown = (res) => {
  if (res) {
    message.destroy()
    if (res.data.type === 'application/json') {
      const reader = new FileReader()
      reader.onload = () => {
        const { resMsg } = JSON.parse(reader.result)
        if (resMsg) message.error(resMsg)
      }
      reader.readAsText(res.data)
    } else {
      const errMsg = res.headers['errmsg']
      let fileName = res.headers['content-disposition']
      if (errMsg) {
        message.error(decodeURI(errMsg))
        return
      }
      if (!fileName) {
        message.error(i18n.global.t('common_text_013'))
        return
      }
      if (fileName.includes('attachment')) {
        fileName = fileName.substr(fileName.indexOf('filename=') + 9, fileName.length)
      }
      const url = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download', decodeURI(fileName))
      document.body.appendChild(link)
      link.click()
    }
  } else {
    message.error(i18n.global.t('common_text_013'))
  }
}

// 下载还原材料模版
export const exportMaterial = (bizType) => {
  const res = `${request.defaults.baseURL}/pay/downloadOrderFile?bizType=${bizType}`
  downTemplateForUrl(res)
}

// 下载国际汇款批量模版
export const exportOutboundsBatchTemplate = (params) => {
  const res = `${request.defaults.baseURL}/outPay/downloadTempFiles?${qs.stringify(params)}`
  downTemplateForUrl(res)
}

// 链接访问下载
export const downTemplateForUrl = (url) => {
  const browserIsIe = () => {
    return (!!window.ActiveXObject || 'ActiveXObject' in window)
  }
  if (browserIsIe()) {
    const iframe = document.createElement('iframe')
    const uri = encodeURI(url)
    iframe.style.display = 'none'
    iframe.setAttribute('name', 'downFailIFrame')
    iframe.src = uri
    iframe.onload = function () {
      document.body.removeChild(iframe)
    }
    document.body.appendChild(iframe)
  } else {
    const aDom = document.createElement('a')
    aDom.href = url
    aDom.download = ''
    document.body.appendChild(aDom)
    aDom.click()
    document.body.removeChild(aDom)
  }
}

// localStorage清空除locale-language的所有key
export function localStorageClear() {
  const language = localStorage.getItem('locale-language')
  localStorage.clear()
  localStorage.setItem('locale-language', language)
}
