<template>
  <a-dropdown :trigger="['click']">
    <a class="main-text-color" :style="style" @click.prevent>
      {{ langMap[$i18n.locale] }}
      <DownOutlined />
    </a>
    <template #overlay>
      <a-menu @click="languageChange">
        <a-menu-item v-for="(value, key) in langMap" :key="key" :disabled="$i18n.locale === key">
          {{ value }}
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
</template>

<script>
import { useStore } from 'vuex'
export default {
  name: 'LangSelector',
  props: {
    style: {
      type: String,
      default () {
        return ''
      }
    }
  },
  setup () {
    const store = useStore()
    const langMap = {
      zh: '中文',
      en: 'English'
    }

    const languageChange = async (e) => {
      localStorage.setItem('locale-language', e.key)
      await store.dispatch('getLanguage')
      location.reload()
    }

    return {
      languageChange,
      langMap
    }
  }
}
</script>

