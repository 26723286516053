export const themes = {
  default: {
    primaryColor: '#4065E0',
    'main-color': '#4065E0', // 主色值
    'text-color': '#4C70E8', // 字体主色值
    'menu-bg-color': '#4065E0', // 左侧菜单栏背景色
    'menu-text-color': '#fff', // 左侧菜单栏字体色
    'menu-selected-color': '#668AFF', // 左侧菜单栏选中色
    'menu-selected-text-color': '#fff', // 左侧菜单栏选中字体色
    'error-color': '#ff4d4f', // 错误颜色
    'table-th-color': '#EAEFFB' // 表格头部底色
  },
  ALPHA: {
    primaryColor: '#4065E0',
    'main-color': '#4065E0', // 主色值
    'text-color': '#4C70E8', // 字体主色值
    'menu-bg-color': '#001529', // 左侧菜单栏背景色
    'menu-text-color': '#ffffffa6', // 左侧菜单栏字体色
    'menu-selected-color': '#4065E0', // 左侧菜单栏选中色
    'menu-selected-text-color': '#fff', // 左侧菜单栏选中字体色
    'error-color': '#ff4d4f', // 错误颜色
    'table-th-color': '#EAEFFB'
  },
  daybreak: {
    primaryColor: '#4065E0',
    'main-color': '#2db7ec', // 主色值
    'text-color': '#2db7ec', // 字体主色值
    'menu-bg-color': '#2d2d39', // 左侧菜单栏背景色
    'menu-text-color': '#fff', // 左侧菜单栏字体色
    'menu-selected-color': '#2db7ec', // 左侧菜单栏选中色
    'error-color': '#ff4d4f', // 错误颜色
    'table-th-color': '#EAEFFB'
  },
  dust: {
    primaryColor: '#F5222D',
    'main-color': '#2db7ec', // 主色值
    'text-color': '#2db7ec', // 字体主色值
    'menu-bg-color': '#2d2d39', // 左侧菜单栏背景色
    'menu-text-color': '#fff', // 左侧菜单栏字体色
    'menu-selected-color': '#2db7ec', // 左侧菜单栏选中色
    'error-color': '#ff4d4f', // 错误颜色
    'table-th-color': '#EAEFFB'
  },
  volcano: {
    primaryColor: '#FA541C',
    'main-color': '#2db7ec', // 主色值
    'text-color': '#2db7ec', // 字体主色值
    'menu-bg-color': '#2d2d39', // 左侧菜单栏背景色
    'menu-text-color': '#fff', // 左侧菜单栏字体色
    'menu-selected-color': '#2db7ec', // 左侧菜单栏选中色
    'error-color': '#ff4d4f', // 错误颜色
    'table-th-color': '#EAEFFB'
  },
  sunset: {
    primaryColor: '#FAAD14',
    'main-color': '#2db7ec', // 主色值
    'text-color': '#2db7ec', // 字体主色值
    'menu-bg-color': '#2d2d39', // 左侧菜单栏背景色
    'menu-text-color': '#fff', // 左侧菜单栏字体色
    'menu-selected-color': '#2db7ec', // 左侧菜单栏选中色
    'error-color': '#ff4d4f', // 错误颜色
    'table-th-color': '#EAEFFB'
  },
  cyan: {
    primaryColor: '#13C2C2',
    'main-color': '#2db7ec', // 主色值
    'text-color': '#2db7ec', // 字体主色值
    'menu-bg-color': '#2d2d39', // 左侧菜单栏背景色
    'menu-text-color': '#fff', // 左侧菜单栏字体色
    'menu-selected-color': '#2db7ec', // 左侧菜单栏选中色
    'error-color': '#ff4d4f', // 错误颜色
    'table-th-color': '#EAEFFB'
  },
  green: {
    primaryColor: '#52C41A',
    'main-color': '#2db7ec', // 主色值
    'text-color': '#2db7ec', // 字体主色值
    'menu-bg-color': '#2d2d39', // 左侧菜单栏背景色
    'menu-text-color': '#fff', // 左侧菜单栏字体色
    'menu-selected-color': '#2db7ec', // 左侧菜单栏选中色
    'error-color': '#ff4d4f', // 错误颜色
    'table-th-color': '#EAEFFB'
  },
  geekblue: {
    primaryColor: '#2F54EB',
    'main-color': '#2db7ec', // 主色值
    'text-color': '#2db7ec', // 字体主色值
    'menu-bg-color': '#2d2d39', // 左侧菜单栏背景色
    'menu-text-color': '#fff', // 左侧菜单栏字体色
    'menu-selected-color': '#2db7ec', // 左侧菜单栏选中色
    'error-color': '#ff4d4f', // 错误颜色
    'table-th-color': '#EAEFFB'
  },
  purple: {
    primaryColor: '#722ED1',
    'main-color': '#2db7ec', // 主色值
    'text-color': '#2db7ec', // 字体主色值
    'menu-bg-color': '#2d2d39', // 左侧菜单栏背景色
    'menu-text-color': '#fff', // 左侧菜单栏字体色
    'menu-selected-color': '#2db7ec', // 左侧菜单栏选中色
    'error-color': '#ff4d4f', // 错误颜色
    'table-th-color': '#EAEFFB'
  }
}
