import axios from '../request'
// 获取OSS临时访问路径
export const querySsoTempUrl = (data) => {
  return axios({
    url: `/basic/querySsoTempUrl`,
    method: 'post',
    data
  })
}

// 获取OSS上传Token
export const getOssUploadToken = (data) => {
  return axios({
    url: `/basic/getOssUploadToken`,
    method: 'post',
    data
  })
}

// 获取OSS上传Token免登录
export const getOssUploadTokenNoLogin = (data) => {
  return axios({
    url: `/basic/getOssUploadTokenNoLogin`,
    method: 'post',
    data
  })
}

// 文件路径获取Url
export const getOssFileUrl = (data) => {
  return axios({
    url: `/user/ossDownLoadFileWithSTSByMap`,
    method: 'post',
    data
  })
}
