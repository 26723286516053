import axios from '../request'
import qs from 'qs'
import { commonFormatDown } from '@/util'

// 结汇收款人列表
export const getPayeeList = (data) => {
  return axios({
    url: `/order/list/payee`,
    method: 'post',
    data
  })
}

// 股东/法人/董事列表
export const getDirectorInfo = (params) => {
  return axios({
    url: `/order/directorInfo/query`,
    method: 'get',
    params
  })
}

// 国际汇款收款人列表
export const getOutboundPayeeList = (data) => {
  return axios({
    url: `/outbound/payee/list`,
    method: 'post',
    data
  })
}

// 结汇收款人名称列表
export const getPayeeNameList = () => {
  return axios({
    url: `/order/payee/shortInfo`,
    method: 'get'
  })
}

// 国际收款人下拉列表
export const outboundPayeeNoPageList = (data) => {
  return axios({
    url: `/outbound/payee/list/noPage`,
    method: 'post',
    data
  })
}

// 检查国际付款付款人
export const outboundPayeeCheck = (data) => {
  return axios({
    url: `/outbound/payee/check`,
    method: 'post',
    data
  })
}

// 三级联动 - 国家
export const payeeCountry = (data) => {
  return axios({
    url: `/outbound/payee/payeeCountryCode`,
    method: 'post',
    data
  })
}

// 三级联动 - 省/州
export const payeeProvince = (data) => {
  return axios({
    url: `/outbound/payee/payeeProvinceByCountryCode`,
    method: 'post',
    data
  })
}

// 三级联动 - 城市
export const payeeCity = (data) => {
  return axios({
    url: `/outbound/payee/payeeCity`,
    method: 'post',
    data
  })
}

// 单笔付款获取最大可汇款金额/手续费
export const paymentSingleTrial = (data) => {
  return axios({
    url: `/payment/single/trial`,
    method: 'post',
    data
  })
}

// 国际汇款获取动态字段信息
export const getDynamicParameter = (data) => {
  return axios({
    url: `/outbound/payee/dynamic/parameter`,
    method: 'post',
    data
  })
}

// 国际付款单笔提交
export const paymentSingleSubmit = (data) => {
  return axios({
    url: `/payment/single/submit`,
    method: 'post',
    data
  })
}

// 国际收款人添加
export const outboundPayeeAdd = (data) => {
  return axios({
    url: `/outbound/payee/add`,
    method: 'post',
    data
  })
}

// 国际收款人修改
export const outboundPayeeUpdate = (data) => {
  return axios({
    url: `/outbound/payee/edit`,
    method: 'post',
    data
  })
}

// 删除国际收款人
export const outboundPayeeDel = (data) => {
  return axios({
    url: `/outbound/payee/delete`,
    method: 'post',
    data
  })
}

// 国际收款人详情
export const outboundPayeeDetail = (data) => {
  return axios({
    url: `/outbound/payee/detail`,
    method: 'post',
    data
  })
}

// 获取国家州列表
export const outboundPayeeProvince = (params) => {
  return axios({
    url: `/outbound/payee/payeeProvince`,
    method: 'get',
    params
  })
}

// 结汇收款人修改
export const updatePayee = (data) => {
  return axios({
    url: `/order/${data.id ? 'modify' : 'add'}/payee`,
    method: 'post',
    data
  })
}

// 删除结汇收款方信息
export const removePayee = (data) => {
  return axios({
    url: `/pay/deletePayee`,
    method: 'post',
    data
  })
}

// 删除结汇付款方信息
export const removePayer = (data) => {
  return axios({
    url: `/pay/deletePayer`,
    method: 'post',
    data
  })
}

// 卡bin查询
export const findBankName = (params) => {
  return axios({
    url: `/order/findBankName`,
    method: 'get',
    params
  })
}

// 结汇收款人是否为白名单
export const getIsWhiteList = (data) => {
  return axios({
    url: `/order/payee/whiteList`,
    method: 'post',
    data
  })
}

// 结汇付款人列表
export const getPayerList = (data) => {
  return axios({
    url: `/order/list/payer`,
    method: 'post',
    data
  })
}

// 结汇付款人名称列表
export const getPayerNameList = () => {
  return axios({
    url: `/order/payer/shortInfo`,
    method: 'get'
  })
}

// 结汇付款人修改
export const updatePayer = (data) => {
  return axios({
    url: `/order/${data.id ? 'modify' : 'add'}/payer`,
    method: 'post',
    data
  })
}

// 结汇收款人详情
export const getPayeeDetail = (params) => {
  return axios({
    url: `/order/payee/detail`,
    method: 'get',
    params
  })
}

// 结汇付款人详情
export const getPayerDetail = (params) => {
  return axios({
    url: `/order/payer/detail`,
    method: 'get',
    params
  })
}

// 结汇收款方列表导出
export const exportPayee = async (data) => {
  const res = await axios({
    url: `/order/export/payee`,
    method: 'post',
    data,
    responseType: 'blob'
  })
  commonFormatDown(res)
}

// 国际汇款收款方列表导出
export const exportOutboundPayee = async (data) => {
  const res = await axios({
    url: `/outbound/payee/export`,
    method: 'post',
    data,
    responseType: 'blob'
  })
  commonFormatDown(res)
}

// 结汇付款方列表导出
export const exportPayer = async (data) => {
  const res = await axios({
    url: `/order/export/payer`,
    method: 'post',
    data,
    responseType: 'blob'
  })
  commonFormatDown(res)
}

// 最大可结汇金额
export const getSettleMaxAmount = (data) => {
  return axios({
    url: `/order/max/settlement/amount`,
    method: 'post',
    data
  })
}

// 付款手续费
export const getFeeAmount = (data) => {
  return axios({
    url: `/order/calculate/fee/amount`,
    method: 'post',
    data
  })
}

// 获取手续费收费标准
export const getFeeReceiveConfig = (data) => {
  return axios({
    url: `/order/query/fee/config`,
    method: 'post',
    data
  })
}

// 单笔付款提交
export const singlePayApply = (data, isRmb) => {
  const url = isRmb ? `/order/v1/single/payment/apply` : `/order/single/payment/apply`
  return axios({
    url,
    method: 'post',
    data
  })
}

// 批量提交
export const batchPayApply = (data, isRmb) => {
  const url = isRmb ? `/order/v1/batch/payment/apply` : `/order/batch/payment/apply`
  return axios({
    url,
    method: 'post',
    data
  })
}

// 批量结汇明细金额
export const getBatchUploadAmount = (data) => {
  return axios({
    url: `/order/upload/paymentFile`,
    method: 'post',
    data
  })
}

// 复核列表页
export const queryOrderPayment = (data) => {
  return axios({
    url: `/order/payment/query`,
    method: 'post',
    data
  })
}

// 付款复核 - 查看明细
export const queryOrderPaymentDetail = (data) => {
  return axios({
    url: `/order/payment/detail`,
    method: 'post',
    data
  })
}

// 付款复核 - 复核
export const queryOrderConfirmDetail = (params) => {
  return axios({
    url: `/order/confirm/detail`,
    method: 'get',
    params
  })
}

// 复核确认
export const orderCheckConfirm = (data) => {
  return axios({
    url: `/order/confirm`,
    method: 'post',
    headers: {
      'Payment-Verification-Token': data.status === '02' ? data.token : 'reject'
    },
    data
  })
}

// 结汇明细列表
export const queryOrderPaymentList = (data) => {
  return axios({
    url: `/order/payment/detail/query`,
    method: 'post',
    data
  })
}

// 结汇明细详情
export const getSingleDetail = (params) => {
  return axios({
    url: `/order/single/detail`,
    method: 'get',
    params
  })
}

// 结汇明细导出
export const exportSettlesDetail = async (data) => {
  const res = await axios({
    url: `/order/payment/detail/export`,
    method: 'post',
    data,
    responseType: 'blob'
  })
  commonFormatDown(res)
}

// 结汇复核导出
export const orderPaymentExport = async (data) => {
  const res = await axios({
    url: `/order/payment/export`,
    method: 'post',
    data,
    responseType: 'blob'
  })
  commonFormatDown(res)
}

// 结汇模版下载
export const downUploadTemplate = async (data) => {
  const res = await axios({
    url: `/order/download/paymentFile`,
    method: 'post',
    data,
    responseType: 'blob'
  })
  commonFormatDown(res)
}

// 还原材料模版下载
export const downReducingMaterialTemplate = async (params) => {
  const res = await axios({
    url: `/order/download/OrderFile`,
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob'
  })
  commonFormatDown(res)
}

// 结汇付款电子回单
export const downElectronicReceipt = async (data) => {
  const res = await axios({
    url: `/pay/downPayFlowSeal`,
    method: 'post',
    data: qs.stringify(data),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob'
  })
  commonFormatDown(res)
}

// 批量下载结汇电子回单
export const batchExportElectronicReceipt = async (data) => {
  const res = await axios({
    url: `/order/exportPdfs`,
    method: 'post',
    data,
    responseType: 'blob'
  })
  commonFormatDown(res)
}

// 批量下载国际汇款电子回单
export const batchExportOutboundElectronicReceipt = async (data) => {
  const res = await axios({
    url: `/payment/download/exportOutboundPdfs`,
    method: 'post',
    data,
    responseType: 'blob'
  })
  commonFormatDown(res)
}

// 查询分支行分页
export const findByMerchantKycEnrol = () => {
  return axios({
    url: `/basic/findByMerchantKycEnrol`,
    method: 'post'
  })
}

// 获取剩余可用额度
export const getAvailabelAmount = () => {
  return axios({
    url: `/pay/queryAvailabelQuota`,
    method: 'post'
  })
}

// 多币种查询
export const getCurAccount = () => {
  return axios({
    url: `/account/queryCurAccount`,
    method: 'post'
  })
}

// 支付密码校验
export const checkPayPassword = (data) => {
  return axios({
    url: `/user/checkPayPassword`,
    method: 'post',
    data
  })
}

// 判断支付密码是否存在
export const isPayPwdExist = () => {
  return axios({
    url: `/user/isPayPwdExist`,
    method: 'get'
  })
}

// 获取国际付款详情
export const getOutboundList = (data) => {
  return axios({
    url: `/payment/query/outboundlist`,
    method: 'post',
    data
  })
}

// 国际汇款详情
export const getOutboundDetail = (data) => {
  return axios({
    url: `/payment/query/outboundDetail`,
    method: 'post',
    data
  })
}

// 国际付款导出
export const exportOutboundDetail = async (data) => {
  const res = await axios({
    url: `/payment/download/outboundlist`,
    method: 'post',
    data,
    responseType: 'blob'
  })
  commonFormatDown(res)
}

// 查询提现账号列表
export const getWithdrawAccountList = () => {
  return axios({
    url: `/withdraw/queryAccountList`,
    method: 'post'
  })
}

// 充值基本信息
export const getRechargeSup = () => {
  return axios({
    url: `/recharge/getRechargeSup`,
    method: 'post'
  })
}

// 提现最大可用金额
export const getWithdrawAvailableAmount = (data) => {
  return axios({
    url: `/withdraw/calculateWithdrawMaxAvailableAmount`,
    method: 'post',
    data
  })
}

// 账户间转账最大可用金额
export const getInAccAvailableAmount = (data) => {
  return axios({
    url: `/inacct/calculateMaxAvailableAmount`,
    method: 'post',
    data
  })
}

// 提现手续费
export const getWithdrawFee = (data) => {
  return axios({
    url: `/withdraw/calculateWithdrawFee`,
    method: 'post',
    data
  })
}

// 提现申请
export const withdrawApply = (data) => {
  return axios({
    url: `/withdraw/withdrawApply`,
    method: 'post',
    headers: {
      'Payment-Verification-Token': data.token
    },
    data
  })
}

// 新增提现账号
export const addOrUpdateAccount = (data) => {
  return axios({
    url: `/withdraw/addOrUpdateAccount`,
    method: 'post',
    data
  })
}

// 账户间转账列表
export const getInAccTrans = (data) => {
  return axios({
    url: `/inacct/queryInAccTrans`,
    method: 'post',
    data
  })
}

// 重复上传提示,时间 one day
export const checkRecentRepeatOrder = (data) => {
  return axios({
    url: `/pay/checkRecentRepeatOrder`,
    method: 'post',
    data
  })
}

// 账户间转账手续费
export const inAccCalcFee = (data) => {
  return axios({
    url: `/inacct/calcFee`,
    method: 'post',
    data
  })
}

// 账户间转账复核
export const transConfirm = (data) => {
  return axios({
    url: `/inacct/transConfirm`,
    method: 'post',
    headers: {
      'Payment-Verification-Token': data.status === '02' ? data.token : 'reject'
    },
    data
  })
}

// 账户间转账申请
export const transApply = (data) => {
  return axios({
    url: `/inacct/transApply`,
    method: 'post',
    data
  })
}

// 获取国家省市区
export const getCountryArea = (params) => {
  return axios({
    url: `/account/getCountryArea`,
    method: 'get',
    params
  })
}

// 获取总行列表
export const getGeneralBankList = (data) => {
  return axios({
    url: `/basic/queryGeneralBank`,
    method: 'post',
    data
  })
}

// 分页获取银行列表
export const getBankList = (data) => {
  return axios({
    url: `/basic/findBranchByParam`,
    method: 'post',
    data
  })
}

// 获取付款方国家
export const getRegionList = (data) => {
  return axios({
    url: `/basic/queryRegionList`,
    method: 'post',
    data
  })
}

// 获取商户信息
export const getMerchantProInfos = (params) => {
  return axios({
    url: `/user/getMerchantProInfos`,
    method: 'get',
    params
  })
}

// 查询外币复核列表以及批量列表
export const getForeignPayConfirmList = (data) => {
  return axios({
    url: `/payment/review/list/query`,
    method: 'post',
    data
  })
}

// 查询外币付款详情
export const getForeignPayConfirmDetailList = (data) => {
  return axios({
    url: `/outPay/queryForeignPayConfirmDetailList`,
    method: 'post',
    data
  })
}

// 获取汇率及手续费
export const getForeignPayConfirmRateInfo = (params) => {
  return axios({
    url: `/outPay/queryForeignPayConfirmRateInfo`,
    method: 'post',
    params
  })
}

// 国际汇款复核提交
export const paymentConfirm = (data) => {
  return axios({
    url: `/payment/review/submit`,
    method: 'post',
    headers: {
      'Payment-Verification-Token': data.status === '02' ? data.token : 'reject'
    },
    data
  })
}

// 国际汇款复核详情
export const outboundReviewDetails = (params) => {
  return axios({
    url: `/payment/review/detail`,
    method: 'get',
    params
  })
}

// 国际汇款提交
export const payBatchCommit = (data) => {
  return axios({
    url: `/outPay/payBatchCommit`,
    method: 'post',
    data
  })
}

// 结汇复核导出
export const outboundsReviewExport = async (data) => {
  const res = await axios({
    url: `/payment/review/list/export`,
    method: 'post',
    data,
    responseType: 'blob'
  })
  commonFormatDown(res)
}

// 国际汇款支持国家
export const getOutboundsCountries = () => {
  return axios({
    url: `/outPay/queryCountrySupported`,
    method: 'get'
  })
}

// 国际汇款扣款币种
export const getDebitCurrencys = (params) => {
  return axios({
    url: `/outPay/getDebitCurrencys`,
    method: 'get',
    params
  })
}

// 国际汇款付款币种
export const getPayeeCurrencys = (params) => {
  return axios({
    url: `/outPay/getPayeeCurrencys`,
    method: 'get',
    params
  })
}

// 判断LOCAL/SWIFT支持
export const getOutPayType = (params) => {
  return axios({
    url: `/outPay/getOutPayType`,
    method: 'get',
    params
  })
}

// 判断收款人新增LOCAL/SWIFT支持
export const getOutPayTypeByType = (params) => {
  return axios({
    url: `/outPay/getOutPayType/byType`,
    method: 'get',
    params
  })
}

// 金额是否取整
export const dynamicPrecision = (data) => {
  return axios({
    url: `/outbound/payee/dynamic/precision`,
    method: 'post',
    data
  })
}

// 预览汇率
export const getOutboundTradeRate = (data) => {
  return axios({
    url: `/outPay/queryMerOutboundTradeRate`,
    method: 'post',
    data
  })
}

// 国际汇款批量上传Excel
export const outpaymentUploadFileByOss = (data) => {
  return axios({
    url: `/outPay/paymentUploadByOss`,
    method: 'post',
    data
  })
}

// 获取备付金银行详情
export const getBankSuppDetail = (data) => {
  return axios({
    url: `/basic/getBankSuppDetail`,
    method: 'post',
    data
  })
}

// 国际汇款批次导出
export const exportOutbounds = async (data) => {
  const res = await axios({
    url: `/pay/outBoundBatchExport`,
    method: 'post',
    data: qs.stringify(data),
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
  commonFormatDown(res)
}

// 国际汇款上传交易支持材料
export const paySupportFileUpload = (data) => {
  return axios({
    url: `/outPay/paySupportFileUpload`,
    method: 'post',
    data
  })
}

// 国际汇款单笔汇率
export const queryTradeRate = (data) => {
  return axios({
    url: `/payment/single/queryTradeRate`,
    method: 'post',
    data
  })
}

// 查询是否有优惠券
export const queryCoupon = (data) => {
  return axios({
    url: `/coupon/query`,
    method: 'post',
    data
  })
}

// 人民币账户列表
export const queryRmbAccount = (data) => {
  return axios({
    url: `/order/query/account/info`,
    method: 'post',
    data
  })
}

// 人民币单账户单笔提现提交
export const singleWithdrawApply = (data) => {
  return axios({
    url: `/order/v1/single/withdraw/apply`,
    method: 'post',
    data
  })
}

// 人民币单账户批量提现提交
export const batchWithdrawApply = (data) => {
  return axios({
    url: `/order/v1/batch//withdraw/apply`,
    method: 'post',
    data
  })
}

// 人民币账户流水明细列表
export const queryRmbAcctModify = (data) => {
  return axios({
    url: '/order/rmb/acct/modify/query',
    method: 'post',
    data
  })
}

// 人民币账户流水明细导出
export const exportRmbAcctModify = async (data) => {
  const res = await axios({
    url: `/order/rmb/acct/modify/export`,
    method: 'post',
    data,
    responseType: 'blob'
  })
  commonFormatDown(res)
}
