import axios from '../request'
import qs from 'qs'
import { commonFormatDown, downTemplateForUrl } from '@/util'

// 展示标签
export const getChildrenMenu = (params) => {
  return axios({
    url: `/user/queryChildMenu`,
    method: 'get',
    params
  })
}

// 账务务流水查询
export const getCurFlow = (data) => {
  return axios({
    url: `/pay/queryCurFlow`,
    method: 'post',
    data
  })
}

// 付款查询
export const getPayFlow = (data) => {
  return axios({
    url: `/pay/queryPayFlow`,
    method: 'post',
    data
  })
}

// 充值流水查询
export const getRechargeRecord = (data) => {
  return axios({
    url: `/recharge/queryRechargeRecord`,
    method: 'post',
    data
  })
}

// 提现查询
export const getWithdrawRecord = (data) => {
  return axios({
    url: `/withdraw/queryWithdrawRecord`,
    method: 'post',
    data
  })
}

// 手续费查询
export const getFeeRecord = (data) => {
  return axios({
    url: `/pay/queryFee`,
    method: 'post',
    data
  })
}

// 充值基本信息
export const getRechargeSup = (data) => {
  return axios({
    url: `/recharge/getRechargeSup`,
    method: 'post',
    data
  })
}

// 获取人民币付款详情列表
export const getCNYPayConfirmDetailList = (data) => {
  return axios({
    url: `/pay/queryCNYPayConfirmDetailList`,
    method: 'post',
    data
  })
}

// 对账单列表
export const getRecords = (data) => {
  return axios({
    url: `/balance/queryList`,
    method: 'post',
    data
  })
}

// 对账单下载
export const downRecords = async (data) => {
  const res = await axios({
    url: `/balance/requestFileUrl`,
    method: 'post',
    data
  })
  downTemplateForUrl(res.data.data)
}

// 账务流水导出
export const exportAccountFlow = async (data) => {
  const res = await axios({
    url: `/pay/exportFlow`,
    method: 'post',
    data: qs.stringify(data),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob'
  })
  commonFormatDown(res)
}

// 付款流水导出
export const exportPayFlow = async (data) => {
  const res = await axios({
    url: `/pay/exportPayment`,
    method: 'post',
    data: qs.stringify(data),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob'
  })
  commonFormatDown(res)
}

// 下载付款电子回单
export const downPayReceipts = async (data) => {
  const res = await axios({
    url: `/pay/exportPdfs`,
    method: 'post',
    data: qs.stringify(data),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob'
  })
  commonFormatDown(res)
}

// 批量下载提现电子回单
export const downWithdrawReceipts = async (data) => {
  const res = await axios({
    url: `/withdraw/batchWithdrawPdf`,
    method: 'post',
    data,
    responseType: 'blob'
  })
  commonFormatDown(res)
}

// 校验人民币批次电子回单
export const checkBatchPayReceipt = (params) => {
  return axios({
    url: `/pay/checkBatchPayReceipt`,
    method: 'post',
    params
  })
}

// 批量下载付款-人民币电子回单
export const downBatchPayReceipt = async (data) => {
  const res = await axios({
    url: `/pay/downBatchPayReceipt`,
    method: 'post',
    data: qs.stringify(data),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob'
  })
  commonFormatDown(res)
}

// 下载付款-人民币电子回单
export const downPayFlowSeal = async (data) => {
  const res = await axios({
    url: `/pay/downPayFlowSeal`,
    method: 'post',
    data: qs.stringify(data),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob'
  })
  commonFormatDown(res)
}

// 下载付款-外币电子回单
export const downOutPayReceipt = async (data) => {
  const res = await axios({
    url: `/pay/downOutPayReceipt`,
    method: 'post',
    data: qs.stringify(data),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob'
  })
  commonFormatDown(res)
}

// 下载付款人民币批次交易
export const downPayDetailCny = async (data) => {
  const res = await axios({
    url: `/pay/downloadAllExcelDetail`,
    method: 'post',
    data: qs.stringify(data),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob'
  })
  commonFormatDown(res)
}

// 下载人民币批次失败交易
export const downPayDetailFailCny = async (data) => {
  const res = await axios({
    url: `/pay/downloadExcelFailDetail`,
    method: 'post',
    data: qs.stringify(data),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob'
  })
  commonFormatDown(res)
}
// 下载付款外币批次交易
export const dowPayDetailForeign = async (data) => {
  const res = await axios({
    url: `/outPay/downloadDetail`,
    method: 'post',
    data: qs.stringify(data),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob'
  })
  commonFormatDown(res)
}

// 充值流水导出
export const exportRecharge = async (data) => {
  const res = await axios({
    url: `/recharge/rechargeExport`,
    method: 'post',
    data: qs.stringify(data),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob'
  })
  commonFormatDown(res)
}

// 提现流水导出
export const exportWithdraw = async (data) => {
  const res = await axios({
    url: `/withdraw/withdrawExport`,
    method: 'post',
    data: qs.stringify(data),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob'
  })
  commonFormatDown(res)
}

// 手续费流水导出
export const exportFee = async (data) => {
  const res = await axios({
    url: `/pay/feeTranFlowQuery4Export`,
    method: 'post',
    data: qs.stringify(data),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob'
  })
  commonFormatDown(res)
}

// 下载充值回单
export const downRechargeReceipt = async (data) => {
  const res = await axios({
    url: `/recharge/exportRechargePdf`,
    method: 'post',
    data: qs.stringify(data),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob'
  })
  commonFormatDown(res)
}

// 下载提现回单
export const downWithdrawReceipt = async (data) => {
  const res = await axios({
    url: `/withdraw/exportWithdrawPdf`,
    method: 'post',
    data: qs.stringify(data),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob'
  })
  commonFormatDown(res)
}

// 下载账户流水
export const downloadAccountFlowPdf = async (data) => {
  const res = await axios({
    url: `/pay/downloadAccountFlowPdf`,
    method: 'post',
    data,
    responseType: 'blob'
  })
  commonFormatDown(res)
}
