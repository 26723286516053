<template>
  <div id="materialSubmit">
    <header-nav :content="`${$t('pages_router_003')} / ${$t('pages_router_009')}`" />
    <div class="common-card-body">
      <div v-if="isAdvance || isUpdate" class="type-choose">
        <div class="submit-explain">
          <p v-if="isAdvance">{{ $t("pages_receive_079") }}</p>
          <p v-if="isAdvance">{{ $t("pages_receive_080") }}</p>
          <p v-if="isUpdate">{{ $t('pages_receive_117') }}</p>
        </div>
        <div class="choose-form flex-align ant-round-form">
          <div class="flex-align" style="width: 500px">
            <a-col :span="10"><span class="part-label">{{ $t("pages_receive_003") }}: </span></a-col>
            <a-select
              v-model:value="vaId"
              show-search
              option-label-prop="label"
              option-filter-prop="label"
              :disabled="isUpdate"
              @change="vaChange"
            >
              <a-select-option
                v-for="va in vaList"
                :key="va.id"
                :value="va.id"
                :label="
                  `${
                    va.simpleName ? va.simpleName + '-' : va.simpleName
                  }${va.currency ? va.currency + '-' : va.currency}${
                    va.accountNo
                  }`
                "
              >
                {{ va.simpleName ? va.simpleName + "-" : va.simpleName
                }}{{ va.currency ? va.currency + "-" : va.currency
                }}{{ va.accountNo }}
              </a-select-option>
            </a-select>
          </div>
          <div :style="{visibility: transTypeText ? 'visible' : 'hidden'}" class="part flex-align">
            <span class="part-label" style="margin-right: 32px">{{ $t("pages_receive_081") }}: </span>
            <a-button type="round" style="font-weight: 600; min-width: 150px">{{ transTypeText }}</a-button>
          </div>
        </div>
      </div>
      <div v-else class="material-display">
        <div class="material-info">
          <div class="info-area flex-align" style="padding-left: 0">
            <span>{{ $t('pages_receive_067') }}: </span>
            <span>{{ materialInfo.payerName }}</span>
          </div>
          <div class="info-area flex-align">
            <span>{{ $t('pages_receive_013') }}: </span>
            <span>{{ materialInfo.transTypeText }}</span>
          </div>
          <div class="info-area flex-align" style="padding-right: 0">
            <span>{{ $t('pages_router_004') }} :</span>
            <span>{{ materialInfo.receiveAccount }}</span>
          </div>
          <div class="info-area flex-align" style="padding-left: 0">
            <span>{{ $t('pages_receive_070') }}: </span>
            <span>{{ materialInfo.receiveAmtText }}</span>
          </div>
          <div class="info-area flex-align">
            <span>{{ $t('pages_receive_115') }}: </span>
            <span>{{ materialInfo.fillAmtText }}</span>
          </div>
        </div>
        <div class="submit-explain" style="margin-top: 8px">{{ $t("pages_receive_116") }}</div>
      </div>
      <div class="material-body"><router-view /></div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { _receive } from '@/api/index'
import HeaderNav from '@/components/header-nav'
import { getTradeStatusName } from '@/util'
export default {
  name: 'MaterialSubmit',
  components: {
    'header-nav': HeaderNav
  },
  setup() {
    const router = useRouter()
    const route = useRoute()

    const state = reactive({
      isAdvance: route.query.isAdvance,
      isUpdate: route.query.isUpdate,
      transTypeText: '',
      vaId: +route.query.vaId || '',
      vaList: [],
      materialInfo: {}
    })

    const vaChange = (vaId) => {
      const vaInfo = state.vaList.find(va => va.id === vaId)
      const { transTypeText, transType } = vaInfo
      state.transTypeText = transTypeText
      state.vaId = vaId
      const query = { vaId, transType }
      if (state.isAdvance) query.isAdvance = state.isAdvance
      if (['00', '10'].includes(vaInfo.transType)) {
        router.replace({ path: '/receive/material_submit/e_commerce', query })
      }
      if (['20', '30'].includes(vaInfo.transType)) {
        delete query.transType
        router.replace({ path: '/receive/material_submit/t_trade', query })
      }
    }

    const loadMaterialInfo = async () => {
      const materialInfo = sessionStorage.getItem('materialInfo')
      if (materialInfo) state.materialInfo = JSON.parse(materialInfo)
      else router.replace('/home/index')
    }

    const loadAllVAs = async () => {
      let data = []
      const vaList = sessionStorage.getItem('vaList')
      if (vaList) {
        data = JSON.parse(vaList)
      } else {
        const res = await _receive.getAllVAs()
        if (res.data.succ) data = res.data.data
      }
      state.vaList = data.map((item) => {
        return Object.assign({}, item, {
          transTypeText: getTradeStatusName(item.transType, 'receiveTransType')
        })
      })
      if (state.vaId) {
        const vaInfo = state.vaList.find(va => va.id === state.vaId)
        state.transTypeText = vaInfo.transTypeText
      }
    }

    const initPage = () => {
      if (state.isAdvance || state.isUpdate) loadAllVAs()
      else loadMaterialInfo()
    }

    watch(
      () => router.currentRoute.value,
      () => initPage(),
      { immediate: true }
    )

    return {
      initPage,
      vaChange,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#materialSubmit {
  .common-card-body {
    padding: 18px 48px;
    text-align: left;
    .type-choose, .material-display {
      .submit-explain {
        padding-bottom: 9px;
        border-bottom: 1px solid #f1f1f1;
        color: #666;
        p {
          margin-bottom: 3px;
        }
      }
      .choose-form {
        margin-top: 24px;
        .part {
          margin-left: 88px;
        }
      }
    }
    .material-display {
      .material-info {
        color: #000;
        display: flex;
        flex-wrap: wrap;
        .info-area {
          flex: 0 0 33.33%;
          padding: 0 24px;
          margin-bottom: 18px;
          span:nth-child(2n + 1) {
            flex: 1;
          }
          span:nth-child(2n) {
            flex: 2;
            border-radius: 28px;
            border: 1px solid #E9E9E9;
            font-weight: 500;
            padding: 4px 0;
            display: block;
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .material-body {
      margin-top: 24px;
    }
  }
}
</style>
