import axios from '../request'

// 登录
export function login (data) {
  return axios({
    url: `/user/login`,
    method: 'post',
    data
  })
}

// 登出
export const loginOut = () => {
  return axios({
    url: `/user/logout`,
    method: 'post'
  })
}

// 检查用户是否已登陆
export const loginCheck = () => {
  return axios({
    url: `/user/loginCheck`,
    method: 'post'
  })
}

// 字段值映射
export function getLanguage (data) {
  let language = localStorage.getItem('locale-language')
  if (!language) {
    language = (navigator.language || navigator.browserLanguage).toLowerCase().substr(0, 2)
  }
  return axios({
    url: `/user/getLanguage?language=${language}`,
    method: 'post',
    data
  })
}

// 获取注册验证码
export function getVerificationCode (data) {
  return axios({
    url: `/user/getVerificationCode`,
    method: 'post',
    data
  })
}

// 获取销售链接推荐吗
export const getRegisterUser = (params) => {
  return axios({
    url: `/user/queryRecommendCode`,
    method: 'post',
    params
  })
}

// 检查用户注册信息
export const checkUserInfo = (data) => {
  return axios({
    url: `/user/checkUserInfo`,
    method: 'post',
    data
  })
}

// 用户注册
export const registerUser = (data) => {
  return axios({
    url: `/user/registerUser`,
    method: 'post',
    data
  })
}

// 重置密码发送邮件请求
export const resetPwdMail = (params) => {
  return axios({
    url: `/user/resetPwdMail`,
    method: 'post',
    params
  })
}

// 根据token获取邮箱
export const getEmailInfoByToken = (params) => {
  return axios({
    url: `/user/decryptEmailInfo`,
    method: 'post',
    params
  })
}

// 重置密码
export const resetUserPwd = (data) => {
  return axios({
    url: `/user/resetUserPwd`,
    method: 'post',
    data
  })
}

// 授权登录
export const oauthLogin = (data) => {
  return axios({
    url: `/oauth/login`,
    method: 'post',
    data
  })
}

// 授权登录页面参数校验
export const checkIn = (data) => {
  return axios({
    url: `/oauth/checkin`,
    method: 'post',
    data
  })
}
// 登录后页面参数校验
export const checkLoginStatus = (data) => {
  return axios({
    url: `/oauth/checkLoginStatus`,
    method: 'post',
    data
  })
}
// 授权
export const confirmAuth = (data) => {
  return axios({
    url: `/oauth/confirmAuth`,
    method: 'post',
    data
  })
}

// 上次登录时间
export const queryLastLoginDate = (data) => {
  return axios({
    url: `/user/queryLastLoginDate`,
    method: 'post',
    data
  })
}
