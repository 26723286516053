<template>
  <div id="mainPage">
    <left-menu />
    <div id="rightMenu">
      <div class="menu-header flex-align">
        <a-tooltip :title="$store.getters.getUserInfo.merchantName">
          <div class="merchant-name">{{ $t('pages_main_053') }}{{ $store.getters.getUserInfo.merchantName }}</div>
        </a-tooltip>
        <div class="cur-list">
          <div
            v-for="(cur, index) in $store.getters.curList"
            :key="index"
            class="cur"
          >
            <a-tooltip v-if="index === 3" placement="bottom">
              <template #title>
                <div>{{ $t('pages_main_025') }}</div>
                <div>{{ $t('pages_main_026') }}</div>
              </template>
              <question-circle-filled class="ic-tip" />
            </a-tooltip>
            <div class="cur-name flex-align">
              <img :src="getCountryLogo(cur.curType)">
              <span>{{ cur.curType }}</span>
            </div>
            <div class="available-amount">
              {{ cur.totalAmount ? parseMoney(cur.totalAmount, cur.curType) : '0.00' }}
            </div>
            <div v-if="cur.freezeAmount !== 0" class="wait-amount">
              <span>{{ $t('pages_main_024') }}{{ parseMoney(cur.freezeAmount, cur.curType) }}</span>
            </div>
          </div>
        </div>
        <a-button
          v-if="$store.getters.curList.length !== 0"
          shape="round"
          type="primary"
          style="font-size: 13px; margin-left: 32px"
          @click="$router.push('/wallet/index')"
        >{{ $t('pages_main_027') }}</a-button>
        <div style="margin-left: auto">
          <div
            class="flex-align"
            style="margin-top: 8px"
          >
            <!-- <schedule-outlined class="ic-global" style="margin: 0 36px" />
          <bell-outlined class="ic-global" /> -->
            <!-- <div v-if="!['港勤支付', 'ALREMIT'].includes(themeData.main.name)" class="flex-align help-center" :onclick="'window.open(\''+'http://help.msp.ottpayhk.com/'+'?base='+ baseUrl+'\')'">
            <img src="~@/assets/svgs/help.svg">
            <span>{{ $t('pages_main_023') }}</span>
          </div> -->
            <img class="user-header" src="@/assets/images/user-header.png">
            <a-dropdown>
              <a class="merchant-email" @click.prevent>
                {{ $store.getters.getUserInfo.email }}
              </a>
              <template #overlay>
                <a-menu>
                  <a-menu-item>
                    <a @click="$router.push('/user/account_materials')">{{ $t('pages_router_031') }}</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a @click="$router.push('/user/user_manage')">{{ $t('pages_router_032') }}</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a @click="$router.push('/user/account_security')">{{ $t('pages_router_033') }}</a>
                  </a-menu-item>
                  <!-- <a-menu-item v-if="!['港勤支付', 'ALREMIT'].includes(themeData.main.name)">
                  <a :onclick="'window.open(\''+'http://help.msp.ottpayhk.com/'+'?base='+ baseUrl+'\')'">{{ $t('pages_main_023') }}</a>
                </a-menu-item> -->
                  <a-menu-item>
                    <a @click="signOut">{{ $t('pages_main_028') }}</a>
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
            <global-outlined class="ic-global" style="margin-top: 16px" />
            <language-selector style="margin-top: 16px" />
          </div>
          <div
            v-if="loginTime"
            class="login-time"
          >{{ $t('pages_main_057', [loginTime]) }}</div>
        </div>

      </div>
      <div id="menuContent" class="menu-content" :style="{width: clientWidth < 800 ? '1280px' : 'auto'}">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, reactive, toRefs, inject, onUnmounted, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import LeftMenu from './leftMenu/index'
import { _login } from '@/api/index'
import { getCountryLogo, parseMoney, localStorageClear } from '@/util'
import { useStore } from 'vuex'
import Cookies from 'js-cookie'
import LangSelector from '@/components/lang-sel'
export default defineComponent({
  name: 'MainPage',
  components: {
    'left-menu': LeftMenu,
    'language-selector': LangSelector
  },
  setup() {
    let timer = null
    const router = useRouter()
    const store = useStore()
    const state = reactive({
      themeData: inject('$themeData'),
      clientWidth: 0,
      loginTime: null,
      currentTime: new Date().getTime(),
      baseUrl: window.location.protocol + '//' + window.location.host
    })
    onMounted(() => {
      state.clientWidth = document.documentElement.clientWidth
    })
    onUnmounted(() => {
      window.onmousemove = null
      clearInterval(timer)
    })
    const init = async () => {
      loadMouseWatch()
      loadLoginTime()
      loadTimer()
      store.dispatch('getCurList')
    }
    const signOut = async () => {
      await _login.loginOut()
      Cookies.remove('last_page_name')
      Cookies.remove('SESSION_ID')
      localStorageClear()
      router.replace('/login')
    }
    const loadTimer = () => {
      timer = setInterval(() => {
        loadIsExpired()
      }, 5000)
    }
    const loadIsExpired = () => {
      if (new Date().getTime() - state.currentTime > 5 * 1000 * 60) {
        clearInterval(timer)
        router.push('/user_leave')
      }
    }
    const loadMouseWatch = () => {
      window.onmousemove = () => {
        state.currentTime = new Date().getTime()
      }
    }
    const loadLoginTime = async () => {
      const res = await _login.queryLastLoginDate()
      if (res.data.succ) state.loginTime = res.data.data.lastLoginDate
    }
    init()
    return {
      getCountryLogo,
      parseMoney,
      signOut,
      init,
      ...toRefs(state)
    }
  }
})
</script>
<style lang="less" scoped>
  #mainPage {
    display: flex;
    #rightMenu {
      width: 100%;
      background: #F8F9FB;
      height: 100vh;
      overflow-y: hidden;
      .menu-header {
        background: #fff;
        padding: 8px 24px 8px 8px;
        white-space: nowrap;
        height: 80px;
        position: relative;
        min-width: 960px;
        .merchant-name {
          font-size: 16px;
          font-weight: 600;
          color: #000;
          position: absolute;
          right: 28px;
          top: 8px;
          width: 400px;
          text-align: right;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .cur-list {
          display: flex;
          margin-left: 24px;
          font-weight: 500;
          .cur {
            padding: 0 36px;
            border-right: 1px solid #ECEFFC;
            text-align: left;
            position: relative;
            .ic-tip {
              color: @main-color;
              top: 2px;
              right: 2px;
              font-size: 15px;
              position: absolute;
            }
            .cur-name {
              img {
                width: 21px;
                height: 14px;
              }
              span {
                margin-left: 8px;
              }
            }
            .wait-amount {
              color: @text-color;
              font-size: 10px;
            }
          }
          .cur:first-child {
            padding-left: 0px;
          }
          .cur:last-child {
            border-right: 0;
          }
        }
        .help-center {
          font-size: 13px;
          color: #1F223E;
          margin-top: 16px;
          cursor: pointer;
          img {
            width: 18px;
            margin: 0 5px 0 24px;
          }
        }
        .user-header {
          width: 30px;
          height: 30px;
          margin: 16px 0 0 24px;
        }
        .merchant-email {
          margin: 16px 24px 0 8px;
          font-size: 13px;
          text-align: left;
          color: #1F223E;
          position: relative;
        }
        .login-time {
          font-size: 11px;
          text-align: right;
        }
      }
      .menu-content {
        padding: 18px 128px;
        height: calc(100vh - 90px);
        overflow-y: scroll;
        overflow-x: hidden;
        min-width: 960px;
      }
        @media screen and (min-width: 1200px) and (max-width: 1349px) {
          .menu-content {
              padding: 18px 50px;
            }
        }
        @media screen and (max-width: 1200px) {
          .menu-content {
            padding: 18px 25px;
          }
        }
    }
  }
</style>

